<template>
  <div class="component-presentations md:mb-6 bg-white overflow-hidden">
    <div>
      <div class="calculator-content relative p-logo-x h-40 md:h-64 py-12">
        <div class="hidden lg:block lg:w-1/2 relative z-20">
          <h4 ref="step__subheadline1" class="text-red-500 text-2xl mb-0">Step 2:</h4>
          <h3 ref="step__headline1" class="font-bold leading-vtight text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-2">Identify presentation preferences.</h3>
        </div>
        <div class="hero-overlay absolute top-0 bottom-0 left-0 w-full z-10"></div>
      </div>
      <div class="bg-gray-300 lg:bg-gray-700 p-logo-x py-8 lg:text-white">
        <div class="block lg:hidden">
          <h4 ref="step__subheadline2" class="text-red-500 text-2xl mb-0">Step 2:</h4>
          <h3 ref="step__headline2" class="font-bold leading-vtight text-2xl md:text-3xl mb-2">Identify presentation preferences.</h3>
        </div>
        <div class="lg:flex">
          <div class="flex flex-grow-0 flex-shrink-0 mr-5">
            <div class="mr-2" ref="step__tipicon">
               <span class="icon icon-syringe text-2xl md:text-4xl"></span>
            </div>
            <div>
              <h3 class="font-bold leading-tight text-2xl md:mt-1 mb-3 whitespace-no-wrap" ref="step__tip">Vaccine Facts:</h3>
            </div>
          </div>
          <div class="w-full lg:w-1/2 lg:pr-6" ref="step__col1">
            <h4 class="text-md mt-2 mb-0">
              Each year, on average, up to 20% of the U.S. population gets the flu.
            </h4>
          </div>
          <div class="w-full lg:w-1/2" ref="step__col2">
            <h4 class="text-md mt-2 mb-1">
              Adults 18-64 years of age account for almost 60% of reported flu hospitalizations.<sup>*</sup>
            </h4>
          </div>
        </div>
      </div>

      <div class="pt-12 md:py-12 p-logo-x">
        <div class="md:flex">
          <h3 class="mt-0 mb-0 font-light text-xl md:text-2xl leading-tight">Select presentation preferences:</h3>

          <div class="ml-auto flex-shrink-0 mb-8 mt-3 md:mb-0 md:mt-0">
            <label class="flex flex-shrink-0 items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="toggleChecked">
              <input type="checkbox" v-model="checked" id="toggleChecked" class="form-checkbox cursor-pointer shadow-inner h-6 w-6 text-gray-300 bg-gray-200 mr-3" @change="setPreferencesChecked()">
              <span class="flex-shrink-0">Select all</span>
            </label>
          </div>
        </div>

        <div class="sticky-container" sticky-container>

          <div v-if="showValidation">
            <h3 class="text-red-500 text-sm leading-none">Please select at least one presentation type to continue.</h3>
          </div>

          <div class="md:flex mt-4 md:mt-8 mb-12">
            <div class="w-full md:w-1/2 lg:pr-12 lg:mr-6 lg:border-r-2 border-solid border-gray-400">
              <label class="block mb-3" for="patients_infants"><strong>Infants</strong> <span class="sm:ml-1 text-xs lg:text-base">(6 months to 35 months)</span></label>

              <div v-if="showDoseValidations && !infantValid" class="mb-3">
                 <h3 class="text-red-500 text-sm leading-none">Please select at least one presentation type to continue.</h3>
              </div>

              <div class="sm:flex sm:flex-wrap mb-8 border-b-2 border-solid border-gray-400 pb-8">
                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="infant__prefilled_syringes">
                  <input type="checkbox" v-model="preferences.infant.prefilled_syringes" id="infant__prefilled_syringes" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                  <span>Prefilled syringes</span>
                </label>

<!--  SINGLE DOSE DISABLED PER CLIENT UPDATES 1/10/23 -->
<!--                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="infant__single_dose_vials">-->
<!--                  <input type="checkbox" v-model="preferences.infant.single_dose_vials" id="infant__single_dose_vials" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">-->
<!--                  <span>Single dose vials</span>-->
<!--                </label>-->

                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="infant__multi_dose_vial">
                  <input type="checkbox" v-model="preferences.infant.multi_dose_vial" id="infant__multi_dose_vial" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                  <span>Multi dose vial</span>
                </label>

                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="infant__nasal_spray" v-if="nasalSprays">
                  <input type="checkbox" v-model="preferences.infant.nasal_spray" id="infant__nasal_spray" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                  <span>Nasal sprays</span>
                </label>
              </div>

              <label class="block mb-3" for="patients_youth"><strong>Youth</strong> <span class="sm:ml-1 text-xs lg:text-base">(36 months to 18 years)</span></label>

              <div v-if="showDoseValidations && !youthValid" class="mb-3">
                 <h3 class="text-red-500 text-sm leading-none">Please select at least one presentation type to continue.</h3>
              </div>

              <div class="md:flex md:flex-wrap mb-8 md:mb-0">
                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="youth__prefilled_syringes">
                  <input type="checkbox" v-model="preferences.youth.prefilled_syringes" id="youth__prefilled_syringes" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                    <span>Prefilled syringes</span>
                </label>

<!--  SINGLE DOSE DISABLED PER CLIENT UPDATES 1/10/23 -->
<!--                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="youth__single_dose_vials">-->
<!--                  <input type="checkbox" v-model="preferences.youth.single_dose_vials" id="youth__single_dose_vials" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">-->
<!--                    <span>Single dose vials</span>-->
<!--                </label>-->

                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="youth__multi_dose_vial">
                  <input type="checkbox" v-model="preferences.youth.multi_dose_vial" id="youth__multi_dose_vial" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                    <span>Multi dose vial</span>
                </label>

                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="youth__nasal_spray" v-if="nasalSprays">
                  <input type="checkbox" v-model="preferences.youth.nasal_spray" id="youth__nasal_spray" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                  <span>Nasal sprays</span>
                </label>
              </div>
            </div>

            <div class="md:w-1/2 md:ml-6">
              <label class="block mb-3" for="patients_infants"><strong>Adults</strong> <span class="sm:ml-1 text-xs lg:text-base">(18 years+)</span></label>

              <div v-if="showDoseValidations && !adultValid" class="mb-3">
                 <h3 class="text-red-500 text-sm leading-none">Please select at least one presentation type to continue.</h3>
              </div>

              <div class="md:flex md:flex-wrap mb-8 border-b-2 border-solid border-gray-400 pb-8">
                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="adult__prefilled_syringes">
                  <input type="checkbox" v-model="preferences.adult.prefilled_syringes" id="adult__prefilled_syringes" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                    <span>Prefilled syringes</span>
                </label>

<!--  SINGLE DOSE DISABLED PER CLIENT UPDATES 1/10/23 -->
<!--                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="adult__single_dose_vials">-->
<!--                  <input type="checkbox" v-model="preferences.adult.single_dose_vials" id="adult__single_dose_vials" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">-->
<!--                    <span>Single dose vials</span>-->
<!--                </label>-->

                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="adult__multi_dose_vial">
                  <input type="checkbox" v-model="preferences.adult.multi_dose_vial" id="adult__multi_dose_vial" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                  <span>Multi dose vial</span>
                </label>

                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="adult__nasal_spray" v-if="nasalSprays">
                  <input type="checkbox" v-model="preferences.adult.nasal_spray" id="adult__nasal_spray" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                  <span>Nasal sprays</span>
                </label>
              </div>

              <label class="block mb-3" for="patients_infants"><strong>Seniors</strong> <span class="sm:ml-1 text-xs lg:text-base">(65 years+)</span></label>

              <div v-if="showDoseValidations && !seniorValid" class="mb-3">
                 <h3 class="text-red-500 text-sm leading-none">Please select at least one presentation type to continue.</h3>
              </div>

              <div class="md:flex md:flex-wrap mb-8 md:mb-0">
                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="senior__prefilled_syringes">
                  <input type="checkbox" v-model="preferences.senior.prefilled_syringes" id="senior__prefilled_syringes" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                    <span>Prefilled syringes</span>
                </label>

<!--  SINGLE DOSE DISABLED PER CLIENT UPDATES 1/10/23 -->
<!--                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="senior__single_dose_vials">-->
<!--                  <input type="checkbox" v-model="preferences.senior.single_dose_vials" id="senior__single_dose_vials" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">-->
<!--                    <span>Single dose vials</span>-->
<!--                </label>-->

                <label class="flex items-center w-full lg:w-1/2 mb-3 cursor-pointer" for="senior__multi_dose_vial">
                  <input type="checkbox" v-model="preferences.senior.multi_dose_vial" id="senior__multi_dose_vial" class="form-checkbox shadow-inner cursor-pointer h-6 w-6 text-gray-300 bg-gray-200 mr-3">
                    <span>Multi dose vial</span>
                </label>
              </div>
            </div>
          </div>

          <div class="mb-4 md:md-0 block md:hidden">
            <span class="text-sm">*Source: CDC</span>
          </div>

          <div class="sticky-footer md:flex items-center bg-white md:bg-transparent pb-8 pt-4 md:pb-0 md:pt-0" v-sticky="mobileFormat" sticky-side="bottom" on-stick="animateFooterIn" ref="step__footer">
            <div class="mb-4 md:md-0 hidden md:inline-block">
              <small>*Source: CDC</small>
            </div>
            <div class="sticky-footer__inner md:ml-auto flex justify-center sm:justify-end">
              <button
                class="ga:stepdown flex-grow-0 text-white font-bold text-red-500 hover:text-red-700 transition-color rounded px-4 py-3 mr-4 flex items-center"
                @click="stepDown">
                  <span class="icon icon-chevron-left"></span>
                  <span class="ml-3">Back</span>
              </button>

              <button
                class="ga:stepup flex-grow-0 text-white text-center leading-none font-bold rounded px-3 py-3 flex items-center transition-bg max-w-50 sm:max-w-full"
                v-bind:class="[(stepValid ? 'bg-red-500 hover:bg-red-600' : 'text-gray-800 bg-gray-300 cursor-not-allowed')]"
                @click="stepUp">
                <span class="hidden md:inline">
                  Show me my personalized flu vaccine summary
                </span>
                <span class="block md:hidden">
                  Personalized flu vaccine summary
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Sticky from 'vue-sticky-directive'
import { TimelineLite, CSSPlugin, Expo }  from "gsap/all";

// Tree shaking will remove the necessary gsap plugins if it doesn't think they are being used
// eslint-disable-next-line
const plugins = [ CSSPlugin, Expo ];

export default {
  name: 'presentations',
  props: {
    mobileFormat: Boolean,
    nasalSprays: Boolean,
    preferences: Object
  },

  directives: {Sticky},

  data() {
    return {
      checked: true,
      showValidation: false,
      showDoseValidations: false
    }
  },

  mounted() {
    this.animateIn();

    // this.setPreferencesChecked(true);
    this.initializeSelectAll();
  },

  methods: {
    animateIn() {
      const timeline = new TimelineLite();

      if (window.innerWidth < 1024) {
        timeline.fromTo(this.$refs.step__subheadline2, 1,
          {y:-20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut })

        timeline.fromTo(this.$refs.step__headline2, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')
      } else {
        timeline.fromTo(this.$refs.step__subheadline1, 1,
          {y:-20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut })

        timeline.fromTo(this.$refs.step__headline1, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')
      }

      timeline.fromTo(this.$refs.step__tipicon, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__tip, 1,
        {y:30, autoAlpha: 0},
        {y:0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__col1, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__col2, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

    },

    initializeSelectAll() {
      let vm = this;
      let ages = ['infant', 'youth', 'adult', 'senior'];
      let noSelectionsMade = false;

      // Loop over each age range and check to see if it contains any null/false values, if all age ranges contain no checked options we can unselect the select all
      ages.forEach(function(age){
        if (!vm.ageIndicationContainsSelected(age, false)) {
          noSelectionsMade = true;
        }
      });

      vm.checked = noSelectionsMade;
    },

    // Loop over the age ranges and check if they have selections made or not made
    ageIndicationContainsSelected(age, bool) {
      let vm = this;
      let checkPresentations;
      let criteriaMatch = !bool;

      if (vm.nasalSprays) {
			//single_dose_vials removed 1/10/23 per client direction
        checkPresentations = ['prefilled_syringes', 'multi_dose_vial', 'nasal_spray'];
      } else {
			//single_dose_vials removed 1/10/23 per client direction
        checkPresentations = ['prefilled_syringes', 'multi_dose_vial'];
      }

      // If bool is true, we are looking for a single positive match for checked
      if (bool) {
        checkPresentations.forEach(function(presentation){
          if (vm.preferences[age][presentation]) {
            criteriaMatch = true;
          }
        });
      } else {
        // if bool is false, we are looking for the age indication to return no matches
        checkPresentations.forEach(function(presentation){
          if (vm.preferences[age][presentation]) {
            criteriaMatch = false;
          }
        });
      }

      return criteriaMatch;
    },

    setPreferencesChecked() {
      let vm = this;
      let ages = ['infant', 'youth', 'adult', 'senior'];
      let checkPresentations;

      if (vm.nasalSprays) {
			//single_dose_vials removed 1/10/23 per client direction
        checkPresentations = ['prefilled_syringes', 'multi_dose_vial', 'nasal_spray'];
      } else {
			//single_dose_vials removed 1/10/23 per client direction
        checkPresentations = ['prefilled_syringes', 'multi_dose_vial'];
      }

      ages.forEach(function(age){
        checkPresentations.forEach(function(presentation){
          vm.preferences[age][presentation] = vm.checked;
        })
      });
    },

    // Emit an event telling the calculator to go back to the previous step
    stepDown() {
      this.$emit('stepDown')
    },

    // Emit an event telling the calculator to proceed to the next step
    stepUp() {
      let vm = this;
      if (this.stepValid) {
        this.$emit('stepUp')
      } else {
        if (!vm.selectionsMade && this.infantValid && this.youthValid && this.adultValid && this.seniorValid) {
          vm.showValidation = true;
        }

        if (!this.infantValid || !this.youthValid || !this.adultValid || !this.seniorValid) {
          vm.showDoseValidations = true;
        }
      }
    },

    validateIndication(age) {
      let vm = this;
      let patientDoses = isNaN(parseFloat(vm.preferences[age].patients)) ? 0 : parseFloat(vm.preferences[age].patients);
      let employeeDoses = isNaN(parseFloat(vm.preferences[age].employees)) ? 0 : parseFloat(vm.preferences[age].employees);
      let totalDoses = patientDoses + employeeDoses;
      let selectionMade = false;

      if (typeof(totalDoses) == "number" && totalDoses > 0) {
			//vm.preferences[age].single_dose_vials removed 1/10/23 per client direction
        if (vm.preferences[age].prefilled_syringes || vm.preferences[age].multi_dose_vial || (vm.nasalSprays && vm.preferences[age].nasal_spray)) {
          selectionMade = true;
        }

        return selectionMade;
      } else {
        return true;
      }
    }
  },

  computed: {
    stepValid() {
      return this.selectionsMade && this.infantValid && this.youthValid && this.adultValid && this.seniorValid;
    },

    infantValid() {
      return this.validateIndication('infant');
    },

    youthValid() {
      return this.validateIndication('youth');
    },

    adultValid() {
      return this.validateIndication('adult');
    },

    seniorValid() {
      return this.validateIndication('senior');
    },

    selectionsMade() {
      let vm = this;
      let ages = ['infant', 'youth', 'adult', 'senior'];
      let checkPresentations;
      let selectionsHaveBeenMade = false;

      if (vm.nasalSprays) {
			//single_dose_vials removed 1/10/23 per client direction
        checkPresentations = ['prefilled_syringes', 'multi_dose_vial', 'nasal_spray'];
      } else {
			//single_dose_vials removed 1/10/23 per client direction
        checkPresentations = ['prefilled_syringes', 'multi_dose_vial'];
      }
      // Loop over each age and presentation type to make sure at least one is selected
      ages.forEach(function(age){
        checkPresentations.forEach(function(presentation){
          if (vm.preferences[age][presentation]) {
            selectionsHaveBeenMade = true;
            vm.showValidation = false;
          }
        })
      });

      return selectionsHaveBeenMade;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .calculator-content {
    background-image: url(../assets/step2-hero.jpg);
  }

  .shadow-inner {
    box-shadow: inset 1px 2px 3px 0 rgba(0, 0, 0, 0.2);
  }

  .tip-icon {
    width: 32px;
    min-width: 32px;
  }
</style>

<template>
  <div ref="wrapper">
    <crd-header></crd-header>

    <div class="component-calculator">
      <div class="sticky-container" sticky-container>

      <div v-if="currentStep == 1">
        <patients
          v-bind:mobileFormat="mobileFormat"
          v-bind:preferences="selectedPreferences"
          v-on:stepUp="incrementStep"
          ></patients>
      </div>

      <div v-if="currentStep == 2">
        <presentations
          v-bind:mobileFormat="mobileFormat"
          v-bind:nasalSprays="nasalSprays"
          v-bind:preferences="selectedPreferences"
          v-on:stepUp="incrementStep"
          v-on:stepDown="decrementStep"></presentations>
      </div>

      <div v-if="currentStep == 3">
        <flu-summary
          v-bind:mobileFormat="mobileFormat"
          v-bind:dosages="dosages"
          v-bind:selectedPreferences="selectedPreferences"
          v-on:stepDown="decrementStep"
          v-on:reset="reset"></flu-summary>
      </div>

    </div>
  </div>
</div>

</template>

<script>
// Vendors
import * as Papa from 'papaparse'
import VueScrollTo from 'vue-scrollto'
// import Sticky from 'vue-sticky-directive'

// Components
import CrdHeader from './CrdHeader.vue'
import Patients from './Patients.vue'
import Presentations from './Presentations.vue'
import FluSummary from './FluSummary.vue'

import Debounce from './../modules/Debounce.js'

// import scrollSpy, { Easing } from "vue2-scrollspy"

// Vue.use(scrollSpy, {
//   easing: Easing.Cubic.In
// });

export default {
  name: 'calculator',
  components: {
    CrdHeader,
    Patients,
    Presentations,
    FluSummary
  },
  // directives: {Sticky},

  // Initialize our reactive data to bind to
  data() {
    return {
      currentStep: 1,
      nasalSprays: false,
      dosages: [],
      mobileFormat: true,
      resizeTimeout: true,
      selectedPreferences: {
        infant: {
          prefilled_syringes: true,
          //single_dose_vials: true, //Disabled on 1/10/23 for client updates
          multi_dose_vial: true,
          nasal_spray: true,
          patients: null,
          employees: null
        },
        youth: {
          prefilled_syringes: true,
          //single_dose_vials: true, //Disabled on 1/10/23 for client updates
          multi_dose_vial: true,
          nasal_spray: true,
          patients: null,
          employees: null,
        },
        adult: {
          prefilled_syringes: true,
          //single_dose_vials: true, //Disabled on 1/10/23 for client updates
          multi_dose_vial: true,
          nasal_spray: true,
          patients: null,
          employees: null
        },
        senior: {
          prefilled_syringes: true,
          //single_dose_vials: true, //Disabled on 1/10/23 for client updates
          multi_dose_vial: true,
          nasal_spray: true,
          patients: null,
          employees: null
        }
      }
    }
  },

  created: function () {
    // Parse our CSV data to pass to the Dosage Table
    this.parseDosageData();
  },

  mounted(){
    let vm = this;
    vm.mobileFormat = (window.innerWidth < 768);

    // Listen for resize event and debounce our mobile layout swap
    window.addEventListener("resize", Debounce(function () {
        vm.mobileFormat = (window.innerWidth < 768);
      }, 300)
    );
  },

  methods: {
  // Go to the previous step
    decrementStep() {
      let vm = this;

      vm.scrollTop();

      window.setTimeout(function() {
        vm.currentStep -= 1;
      }, 10);
    },

    // Go to the next step
    incrementStep() {
      let vm = this;

      vm.scrollTop();
      window.setTimeout(function() {
        vm.currentStep += 1;

        window.setTimeout(function() {
          if (vm.$refs.stickyHeader) {
            vm.stickyHeaderHeight = vm.$refs.stickyHeader.offsetHeight + 22;
          }
        }, 30);
      }, 10);
    },

    // Reset data to it's initial state and set the currentStep to our first step
    reset() {
      let vm = this;

      vm.scrollTop();
      window.setTimeout(function() {
        vm.currentStep = 1;
        vm.selectedPreferences = {
          infant: {
            prefilled_syringes: true,
            //single_dose_vials: true,
            multi_dose_vial: true,
            nasal_spray: true,
            patients: null,
            employees: null,
          },
          youth: {
            prefilled_syringes: true,
            //single_dose_vials: true,
            multi_dose_vial: true,
            nasal_spray: true,
            patients: null,
            employees: null,
          },
          adult: {
            prefilled_syringes: true,
            //single_dose_vials: true,
            multi_dose_vial: true,
            nasal_spray: true,
            patients: null,
            employees: null,
          },
          senior: {
            prefilled_syringes: true,
            //single_dose_vials: true,
            multi_dose_vial: true,
            nasal_spray: true,
            patients: null,
            employees: null,
          }
        }
      }, 10);
    },


    scrollTop() {
      let vm = this;
      let options = {
        container: 'body',
        easing: false,
        offset: 0,
        force: true,
        duration: 5,
        cancelable: false,
        x: false,
        y: true
      }

      let cancelScroll = VueScrollTo.scrollTo(vm.$refs.wrapper, 300, options);
      cancelScroll;
    },

    // Loop over the data provided and condtionally remove the nasal spray attribute
    conditionalNasalSpray() {
      let vm = this;
      let nasalSpraysPresent = false;

      vm.dosages.forEach(function(dosage) {
        if ((dosage.Presentation.toLowerCase()).includes('nasal spray')) {
          nasalSpraysPresent = true;
        }
      })

      vm.nasalSprays = nasalSpraysPresent;
    },

    /* Use an external parser to load and parse dosage data from a CSV file
    ** then convert the file to a structured JSON array and set it as our model.dosages
    */
    parseDosageData() {
      let vm = this;
      let fileLocation = process.env.BASE_URL + 'data/dosages.csv';
      Papa.parse(fileLocation, {
        delimiter: ",",
        newline: "↵",
        quoteChar: '"',
        encoding: "UTF-8",
        escapeChar: '"',
        skipEmptyLines: true,
        header: true,
        download: true,
        transformHeader: function(header) {
          // For some reason the parsed headers can sometimes include unshown whitespace, so remove it before we set the data or else the keys are clunky
          let sanitized = header.replace("\r\n", "").replace("\r", "").replace("\n", "")
          return sanitized;
        },
        complete: function(results) {
          if (results && results.data && results.data.length > 0) {
            vm.dosages = results.data;
            vm.conditionalNasalSpray();
          }
        }
      });
    }
  }
}
</script>

<style lang="scss">
  svg {
    max-width: 100%;
  }

  .dosage-content {
    background-color: #5C5C5C;
    background-size: cover;
    background-position: bottom;
  }

</style>

<template>
	<tr>
    <td class="border border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1">
      {{dosage.Manufacturer}}
    </td>
    <td class="border border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1">
      <span v-html="this.$options.filters.setSuperSymbols(dosage.Brand)"></span>
    </td>
    <td class="border border-gray-700 border-solid  px-3 py-2 print:px-1 print:py-1">
      <span v-html="this.$options.filters.setSuperSymbols(dosage.Presentation)"></span>
    </td>
    <td class="border border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1">
      {{dosage.Size}}
    </td>
    <td class="border border-gray-700 border-r-0 border-solid px-3 py-2 print:px-1 print:py-1">
      {{getAge(dosage)}}
    </td>
    <td class="font-bold border border-t border-b border-l-0 border-r-0 border-gray-700 px-2 py-1 text-center bg-gray-300">
      {{totalUnits.toLocaleString()}}
    </td>
  </tr>
</template>


<script>
  export default {
    name: 'brand-table-row',
    props: {
      dosage: Object,
      dosageIndex: Number
    },    
    data () {
      return {
      }
    },

    methods: {
      /* Params: value:integer
      ** Given an integer of months, will return a human friendly string for
      ** how many months or years that value should be displayed as
      */
      getYears(value) {
        let calculatedValue = value;
        if (value > 36) {
          calculatedValue = (value/12) + ' years';
        } else {
          calculatedValue += ' months';
        }

        return calculatedValue;
      },

      /* Params: dosage:object
      ** Builds a human friendly string for what age/range the dosage should be used for
      */
      getAge(dosage) {
        let vm = this;
        let age = "";

        age += vm.getYears(dosage["Age Indication Min"])

        if (dosage["Age Indication Max"]) {
          age += ' - '
          age += vm.getYears(dosage["Age Indication Max"]);
        } else {
          age += '+';
        }

        return age;
      },
    },

    computed: {
      totalDoses() {
        let total = 0;

        total += this.dosage.infant_doses;
        total += this.dosage.youth_doses;
        total += this.dosage.adult_doses;
        total += this.dosage.senior_doses;

        return total;
      },

      totalUnits() {
        let vm = this;
        let total = 0;

        if (vm.totalDoses && vm.totalDoses > 0) {
          let dividedUnits = vm.totalDoses / 10;

          total = Math.ceil(dividedUnits);
        }

        return total;
      },


      // Calculates the total number of units based on the requested doses the user inputs
      unitsNeeded() {
        let vm = this;
        let total = 0;

        if (vm.localQuantity && vm.localQuantity > 0 && vm.dosage.DosesPerUnit) {
          let dividedUnits = vm.localQuantity / vm.dosage.DosesPerUnit;

          total = Math.ceil(dividedUnits);
        }

        return total;
      }
    },

    watch: {
      // When the local quantity changes, we emit those changes up to the parent
      localQuantity (qty) {
        let vm = this;
        if (qty) {
          let qtyNum = parseFloat(qty);
          this.$emit('update', qtyNum, vm.dosage, vm.ageIndication)
        } else {
          this.$emit('update', null, vm.dosage, vm.ageIndication)
        }
      }
    },

    filters: {
      setSuperSymbols: function (value) {
        let superSymbols = ['†', '∆', '∞', 'Ω'];
        let newContent = '';

        if (!value) return ''

        // Loop over the characters in the string and if it matches are subset of characters, super it
        for (var i = 0; i < value.length; i++) {
          let curr = value.charAt(i);

          // eslint-disable-next-line
          // console.log(curr, superSymbols.indexOf(curr));

          if (superSymbols.indexOf(curr) > -1) {
            newContent += '<span class="text-symbol mr-1">'+curr+'</span>';
          } else {
            newContent += curr;
          }
        }

        return newContent;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
import { render, staticRenderFns } from "./CrdHeader.vue?vue&type=template&id=2877d967&scoped=true&"
import script from "./CrdHeader.vue?vue&type=script&lang=js&"
export * from "./CrdHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2877d967",
  null
  
)

export default component.exports
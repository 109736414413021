<template>
  <header class="bg-white py-2 sm:py-3 md:py-5 shadow-xl z-40 relative">
    <div class="flex flex-wrap justify-between items-end">
      <div class="w-full xl:w-auto mb-4 xl:mb-0 lg:mr-auto">
        <img src="../assets/logo-main.png" class="cardinal-logo-img ml-1 lg:ml-4">
      </div>

      <div class="flex items-end w-2/3 sm-p-logo-l xl:w-auto xl:text-center">
        <h1 class="lg:w-auto text-root sm:text-xl lg:text-3xl font-light leading-none header-items">
          2023–2024 Flu vaccine calculator
        </h1>
      </div>

      <div class="flex items-end w-1/3 xl:w-auto ml-auto p-logo-r">
        <div class="header-items w-full">
          <span class="leading-none hidden lg:block font-bold text-sm text-right">Questions?</span>
          <a href="mailto:FluTeam@cardinalhealth.com" class="text-sm text-red-500 block hover:text-red-600 transition-color text-right"><span class="hidden lg:inline icon icon-envelope mr-2"></span><span class="hidden lg:inline underline">FluTeam@cardinalhealth.com</span><span class="font-bold text-xs block lg:hidden leading-tight text-right">Email your <br class="block sm:hidden">questions</span></a>
        </div>
      </div>
    </div>
  </header>
</template>


<script>
export default {
  name: 'CrdHeader',
  data() {
    return {
      open: false
    }
  },

  methods: {
    toggleContact() {
      this.open = !this.open;
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div class="component-dosage-table mt-8 print:mt-6 pb-6 print:pb-0"> 
    <h3 class="font-bold mb-3 pb-2 text-md text-gray-800 print:text-sm leading-none border-b-2 print:border-b border-solid border-gray-400">{{title}}</h3>

     <div class="border-2 print:border border-solid border-blue-200 mt-5 mb-6 py-2 px-3 md:flex print:flex page-break-avoid print:text-tiny">
      <div class="md:mr-8 print:mr-4 leading-none">
        <span class="mr-2">
          {{dosesNeeded.toLocaleString()}} 
        </span>
        <span class="font-bold mb-0 print:text-xs print:text-gray-1000">Doses needed based on your inputs</span>
      </div>
      <div class="leading-none">
        <span class="mr-2" v-bind:class="dosesAllocatedSuccess">
          {{dosesAllocated.toLocaleString()}}
        </span> 
        <span class="font-bold mb-0 print:text-xs print:text-gray-1000">Doses allocated</span>
      </div>
    </div>

    <template v-if="presentationsSelected">
      <table class="table-auto w-borderComp table-striped">
        <tbody>

          <tr v-if="!mobileFormat" class="header-row">
            <th class="px-0 py-1 text-left text-black">
              <h4 class="text-root print:text-xs">Quantity</h4>
              <h5 class="text-gray-700 text-xs print:text-micro font-normal italic">10 doses per 1 orderable unit</h5>
              <div class="flex flex-row">
                <div class="text-gray-600 font-normal text-sm print:text-xxs text-left pl-2 print:pl-0 print:text-center w-1/3">Doses</div>
                <div class="ml-1 text-gray-600 font-normal text-sm print:text-xxs text-center w-2/3 whitespace-no-wrap">Orderable units</div>
              </div>
            </th>
            <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Manufacturer</th>
            <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Brand name</th>
            <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Presentation</th>
            <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Size</th>
            <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Age indication</th>
          </tr>
        
          <dosage-table-row 
            v-for="(dosage, dosageIndex) in filteredData" 
            v-bind:dosage="dosage"
            v-bind:mobileFormat="mobileFormat"
            v-bind:key="dosageIndex"
            v-bind:quantity="dosage[`${ageIndication}_doses`]"
            v-bind:dosageIndex="dosageIndex"
            v-bind:ageIndication="ageIndication"
            @update="dosageUpdated"
            @inputFocused="dosageFocused"
            @inputBlurred="dosageBlurred"/>

          <tr v-if="!filteredData || filteredData.length == 0">
            <td colspan="6" class="border border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1">
              <h3 class="text-sm text-gray-500">No flu vaccines match your presentation selections.</h3>
            </td>
          </tr>

          <tr class="bg-blue-200 print:bg-transparent header-row" v-if="filteredData && filteredData.length > 0 ">
            <td class="border border-white print:border-t-2 print:border-b-2 print:border-l-2 print:border-gray-700 border-solid px-2 py-2 print:bg-gray-300">
              <div class="flex flex-row">
                <div class="w-1/3 text-center">
                  <span>
                    {{dosesAllocated.toLocaleString()}}
                  </span>
                </div>
                <div class="w-2/3 text-center">
                  <span>
                    {{unitsAllocated.toLocaleString()}}
                  </span>
                </div>
              </div>
            </td>
            <td class="border border-white print:border-t-2 print:border-b-2 print:border-r-2 print:border-l-0 print:border-gray-700 border-solid px-3 py-1 print:bg-gray-300">
              <h3 class="font-bold mb-0 print:text-gray-1000">Totals</h3>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template v-if="!presentationsSelected">
      <h3 class="text-gray-500 font-light">There are no flu vaccines that meet your presentation preferences. Please go “Back” to revise your preferences.</h3>
    </template>

  </div>
</template>

<script>
import DosageTableRow from './DosageTableRow.vue'

export default {
  name: 'dosage-table',
  components: {
    DosageTableRow
  },
  props: {
    mobileFormat: Boolean,
    dosages: Array,
    ageMin: Number,
    ageMax: Number,
    ageIndication: String,
    title: String,
    preferences: Object
  },

  data() {
    return {
      displayUnitsNeeded: true,
      dosesPerUnit: null
    }
  },

  created() {
    this.initializeForms();
  },

  updated() {
    this.dosesPerUnitMatch();
  },

  methods: {
    scrollToTop() {
      this.$emit('scrollTop')
    },

    // Loop over the original dosage options given to the component and add a reactive property for alotted doses 
    initializeForms() {
      let vm = this;

      vm.dosesPerUnitMatch();
    },

    dosageUpdated(qty, dosage, ageIndication) {
      dosage[`${ageIndication}_doses`] = qty;
      this.$emit('update', qty, dosage, ageIndication)
    },

    /* Params: dosage:object
    ** Compares dosage data to the defined preferences and returns a boolean for 
    ** whether the dosage should be disabled because it does not match the criteria
    */
    setDisabled(dosage) {
      let vm = this;
      let disabled = false;

      let dosagePresentation = dosage.Presentation.toLowerCase();

      if (dosagePresentation.includes('prefilled syringes')){
        disabled = !vm.preferences.prefilled_syringes;
      } else if (dosagePresentation.includes('multi dose vial')){
        disabled = !vm.preferences.multi_dose_vial;
      } else if (dosagePresentation.includes('single dose vials')) {
        disabled = !vm.preferences.single_dose_vials;
      } else if (dosagePresentation.includes('nasal spray')) {
        disabled = !vm.preferences.nasal_spray;
      }

      return disabled;
    },

    dosageFocused(event) {
      this.$emit('inputFocused', event);
    },

    dosageBlurred() {
      this.$emit('inputBlurred');
    },

    dosesPerUnitMatch() {
      let vm = this;
      let defaultDPU = null;
      let displayUnitsNeeded = true;

      // Loop over the dosages and make sure all of them have the same doses per unit
      vm.dosages.forEach(function(dosage) {
        if (dosage.DosesPerUnit) {
          // Set our default first
          if (defaultDPU == null) {
            defaultDPU = dosage.DosesPerUnit;
          }
          if (Math.ceil(defaultDPU) != Math.ceil(dosage.DosesPerUnit)) {
            displayUnitsNeeded = false;
          }
        }
      });

      vm.displayUnitsNeeded = displayUnitsNeeded;

      // Now that we've looped over the dosages and the units match, set the default
      if (displayUnitsNeeded) {
        vm.dosesPerUnit = defaultDPU;
      }
    },

    /* Emit an event to the parent to let it know we want to start over
    */
    startOver() {
      this.$emit('startOver')
    },

    sortByAge(dosages) {
      let vm = this;
      const fieldSorter = (fields) => (a, b) => fields.map(function(){
        let orderModifier = vm.ageMin == 780 ? (-1) : 1;
        let minA = parseFloat(a['Age Indication Min']) * orderModifier;
        let minB = parseFloat(b['Age Indication Min']) * orderModifier;

        // If the min ages are the same, we have to tie break
        if(minA === minB) {
            let maxA = isNaN(parseFloat(a['Age Indication Max'])) ? 0 : -1;
            let maxB = isNaN(parseFloat(b['Age Indication Max'])) ? 0 : -1;

          if (vm.ageMin == 780) {
            return maxA < maxB ? +1 : maxA > maxB ? -1 : 0;  
          } else {
            return maxA < maxB ? -1 : maxA > maxB ? 1 : 0; 
          }
        }
        
        return minA - minB; 

      }).reduce((p, n) => p ? p : n, 0);

      // Seniors display the 65+ vaccines first then order descending
      if (vm.ageMin == 780) {
        return dosages.sort(fieldSorter(['Age Indication Min','Age Indication Max']));
      } else {
        return dosages.sort(fieldSorter(['Age Indication Min','Age Indication Max']));
      }
    }
  },

  computed: {
    presentationsSelected() {
      return (this.preferences.prefilled_syringes || this.preferences.multi_dose_vial || this.preferences.single_dose_vials || this.preferences.nasal_spray);
    },

    dosesAllocatedSuccess() {
      let vm = this;
      let cssClass;

      if (vm.dosesNeeded == 0) {
        // If the user didn't set a number in step 1
        cssClass = (vm.dosesAllocated == 0 ? 'text-green-500 font-bold' : 'text-red-500 font-bold');
      } else if (vm.dosesNeeded > 0) {
        if (vm.dosesAllocated == vm.dosesNeeded) {
          cssClass = 'text-green-500 font-bold';
        } else if (vm.dosesAllocated < vm.dosesNeeded) {
          cssClass = '';
        } else if (vm.dosesAllocated > vm.dosesNeeded){
          cssClass = 'text-red-500 font-bold';
        }
      }

      return cssClass;
    },

    // Calculates the total number of doses set by the user
    dosesAllocated() {
      let vm = this;
      let total = 0;
      vm.dosages.forEach(function(dosage) {
        if (dosage[`${vm.ageIndication}_doses`]) {
          total += dosage[`${vm.ageIndication}_doses`];
        }
      });

      return total;
    },

    // Calculate the amount of doses to be set by the user based on step 1 quantities
    dosesNeeded() {
      let vm = this;
      let totalDoses = 0;

      if (vm.preferences) {
        totalDoses += vm.preferences.patients ? parseFloat(vm.preferences.patients) : 0;
        totalDoses += vm.preferences.employees ? parseFloat(vm.preferences.employees) : 0;
      }

      return totalDoses;
    },

    // Calculates the total number of units needed based on the requested doses by the user
    unitsNeeded() {
      let vm = this;
      let total = 0;

      if (vm.displayUnitsNeeded && vm.dosesNeeded && vm.dosesPerUnit) {
        let dividedUnits = vm.dosesNeeded / vm.dosesPerUnit;
        total = Math.ceil(dividedUnits);
      }

      return total;
    },

    // Calculates the total number of units based on the requested doses the user
    unitsAllocated() {
      let vm = this;
      let total = 0;

      vm.dosages.forEach(function(dosage) {
        if (dosage[`${vm.ageIndication}_doses`]) {
          let dividedUnits = dosage[`${vm.ageIndication}_doses`] / 10;

          total += Math.ceil(dividedUnits);
        }
      })

      return total;
    },

    // Calculate the amount of doses still needing to be set by the user based on step 1 quantities
    dosesStillNeeded() {
      let vm = this;
      let totalDoses = 0;
      let needed = 0;

      if (vm.preferences) {
        totalDoses += vm.preferences.patients ? parseFloat(vm.preferences.patients) : 0;
        totalDoses += vm.preferences.employees ? parseFloat(vm.preferences.employees) : 0;
      }

      if (totalDoses > 0) {
        needed = totalDoses - vm.dosesAllocated;
      }

      return needed;
    },

    // Constructs a new array of dosages for the given age range
    filteredData() {
      let vm = this;
      let filteredDosages = [];
      let ageFiltered;
      let sortedData;

      if (vm.dosages) {
        // Filter over the array and return only values that fit in our window
        let preFilteredDosages = vm.dosages.filter(function(dosage) {
          if (dosage["Age Indication Max"]) {
            if (dosage["Age Indication Max"] < vm.ageMin) {
              return false;
            } else if (dosage["Age Indication Min"] > vm.ageMax) {
              return false;
            } else {
              return true;
            }
          } else {
            
            return ((dosage["Age Indication Min"] <= vm.ageMax))
          }
        });

        // Senior specific filtering
        if (vm.ageMin == 780) {
          ageFiltered = preFilteredDosages.filter(function(dosage) {
            return !(dosage['Presentation'].toLowerCase()).includes('nasal spray');
          })
        } else {
          ageFiltered = preFilteredDosages;
        }


        // Now that the proper age dosages are filtered, remove any dosages that don't meet the user's selected
        // presentation inputs from step 2
        filteredDosages = ageFiltered.filter(function(dosage){
          return !vm.setDisabled(dosage);
        })


        sortedData = vm.sortByAge(filteredDosages);
      }

      return sortedData;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
tr.disabled {
  opacity: 0.4;
}
</style>

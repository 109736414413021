<template>
  <div class="component-preferences md:mb-6 bg-white overflow-hidden">
    <div>
      <div class="calculator-content p-logo-x h-40 md:h-64 md:py-12 relative">
        <div class="hidden lg:block lg:w-1/2 pr-4 relative z-20">
          <h4 ref="step__subheadline1" class="text-red-500 text-lg md:text-2xl mb-0">Step 1:</h4>
          <h3 ref="step__headline1" class="font-bold leading-vtight text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-2">Identify the age demographics of your population</h3>
          <p ref="step__content1" class="leading-tight text-xl mb-8">There are different vaccines for each age group.</p>
        </div>
        <div class="hero-overlay absolute top-0 bottom-0 left-0 w-full z-10"></div>
      </div>
      <div class="bg-gray-300 lg:bg-gray-700 p-logo-x pt-4 pb-6 md:py-8 lg:text-white">
        <div class="block lg:hidden">
          <h4 ref="step__subheadline2" class="text-red-500 text-lg mb-0">Step 1:</h4>
          <h3 ref="step__headline2" class="font-bold leading-minimal text-3xl md:text-4xl mb-5">Identify the age demographics of your population</h3>
          <p ref="step__content2" class="leading-tight text-lg mb-8">There are different vaccines for each age group.</p>
        </div>

        <div class="lg:flex">
          <div class="flex flex-grow-0 flex-shrink-0 mr-5">
            <div class="mr-2" ref="step__tipicon">
               <span class="icon icon-syringe text-2xl md:text-4xl"></span>
            </div>
            <div ref="step__tip">
              <h3 class="font-bold leading-tight text-2xl md:mt-2 mb-1">Tip:</h3>
            </div>
          </div>

          <div class="w-full lg:w-1/2 lg:pr-6" ref="step__col1">
            <h4 class="font-bold mt-3 mb-0 leading-tight">Don’t forget your staff.</h4>

            <p class="mb-0 text-sm">
              Employers can play an important role in preventing flu, helping to protect employees’ health and reducing losses in productivity and revenue.
            </p>
          </div>
          <div class="w-full lg:w-1/2 lg:pl-6" ref="step__col2">
            <p class="mt-3 mb-0 text-sm">
              During the 2018-2019 flu season, an estimated 20 million employed adults missed four, eight-hour shifts costing employers over $17 billion in lost productivity.<sup>*</sup>
            </p>
          </div>
        </div>
      </div>

      <div class="pt-12 md:py-12 p-logo-x">
        <h3 class="mt-0 mb-5 md:mb-8 font-light text-xl md:text-2xl leading-tight">Enter the number of patients and staff you plan to vaccinate in the 2023-2024 flu season:</h3>

        <div class="sticky-container" sticky-container>

          <div class="md:flex mb-6 md:mb-12">

            <div class="w-full md:w-1/2 lg:mr-6 md:border-r-2 border-solid border-gray-400">
              <h4 class="mb-3 text-xl font-bold">Patients</h4>
              <div class="flex flex-wrap">

                <div class="mb-8 w-1/2 pr-2">
                  <label class="block mb-1" for="patients_infants"><strong>Infants </strong>
                    <span class="sm:lg:ml-1 block lg:inline text-xs lg:text-base">(6 months to 35 months)</span>
                  </label>
                  <cleave
                    :options="cleaveOptions"
                    v-model="preferences.infant.patients"
                    class="block w-full md:max-w-66 border border-gray-400 rounded-sm pt-2 px-2 pb-1 leading-none bg-gray-200 shadow-inner"
                    name="patients_infants"
                    id="patients_infants"
                    maxlength="12"
                    pattern="[0-9]*"
                    inputmode="numeric"></cleave>
                </div>

                <div class="mb-8 w-1/2 pl-2">
                  <label class="block mb-1"><strong>Youth</strong>
                    <span class="lg:ml-1 block lg:inline text-xs lg:text-base">(36 months to 18 years)</span>
                  </label>
                  <cleave
                    :options="cleaveOptions"
                    v-model="preferences.youth.patients"
                    class="block w-full md:max-w-66 border border-gray-400 rounded-sm pt-2 px-2 pb-1 leading-none bg-gray-200 shadow-inner"
                    name="patients_youth"
                    id="patients_youth"
                    maxlength="12"
                    pattern="[0-9]*"
                    inputmode="numeric"></cleave>
                </div>

                <div class="mb-8 w-1/2 pr-2">
                  <label class="block mb-1"><strong>Adults</strong>
                    <span class="lg:ml-1 block lg:inline text-xs lg:text-base">(18 years+)</span>
                  </label>
                  <cleave
                    :options="cleaveOptions"
                    v-model="preferences.adult.patients"
                    class="block w-full md:max-w-66 border border-gray-400 rounded-sm pt-2 px-2 pb-1 leading-none bg-gray-200 shadow-inner" name="patients_adult"
                    id="patients_adult"
                    maxlength="12"
                    pattern="[0-9]*"
                    inputmode="numeric"></cleave>
                </div>

                <div class="mb-8 w-1/2 pl-2">
                  <label class="block mb-1"><strong>Seniors</strong>
                    <span class="lg:ml-1 block lg:inline text-xs lg:text-base">(65 years+)</span>
                  </label>
                  <cleave
                    :options="cleaveOptions"
                    v-model="preferences.senior.patients"
                    class="block w-full md:max-w-66 border border-gray-400 rounded-sm pt-2 px-2 pb-1 leading-none bg-gray-200 shadow-inner"
                    name="patients_senior"
                    id="patients_senior"
                    maxlength="12"
                    pattern="[0-9]*"
                    inputmode="numeric"></cleave>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/2 md:ml-6 mt-4 md:mt-0 pt-8 md:pt-0 border-t-2 md:border-0 border-solid border-gray-400">
              <h4 class="mb-3 text-xl font-bold">Staff</h4>
              <div class="flex flex-wrap">
                <div class="mb-8 w-1/2 pr-2">
                  <label class="block mb-1" for="adult_employees"><strong>Adults</strong>
                    <span class="lg:ml-1 block lg:inline text-xs lg:text-base">(18 years+)</span></label>
                  <cleave
                    :options="cleaveOptions"
                    v-model="preferences.adult.employees"
                    class="block w-full md:max-w-66 border border-gray-400 rounded-sm pt-2 px-2 pb-1 leading-none bg-gray-200 shadow-inner"
                    name="adult_employees"
                    id="adult_employees"
                    maxlength="12"
                    pattern="[0-9]*"
                    inputmode="numeric"></cleave>
                </div>

                <div class="mb-8 w-1/2 pl-2">
                  <label class="block mb-1" for="senior_employees"><strong>Seniors</strong>
                    <span class="lg:ml-1 block lg:inline text-xs lg:text-base">(65 years+)</span>
                  </label>
                  <cleave
                    :options="cleaveOptions"
                    v-model="preferences.senior.employees"
                    class="block w-full md:max-w-66 border border-gray-400 rounded-sm pt-2 px-2 pb-1 leading-none bg-gray-200 shadow-inner"
                    name="senior_employees"
                    id="senior_employees"
                    maxlength="12"
                    pattern="[0-9]*"
                    inputmode="numeric"></cleave>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-4 md:mb-0 block md:hidden">
            <span class="text-sm">*Source: Challenger, Gray & Christmas; January 17, 2019.</span>
          </div>

          <div class="sticky-footer md:flex items-center bg-white md:bg-transparent pb-8 pt-4 md:pb-0 md:pt-0" v-sticky="mobileFormat" sticky-side="bottom" on-stick="animateFooterIn" ref="step__footer">
            <div class="mb-4 md:mb-0 hidden md:inline-block">
              <span class="text-sm">*Source: Challenger, Gray & Christmas; January 17, 2019.</span>
            </div>

            <div class="sticky-footer__inner md:ml-auto">
              <button
                class="ga:stepup text-white font-bold bg-red-500 hover:bg-red-600 transition-bg rounded px-8 py-3 block w-full md:w-auto md:flex md:items-center"
                @click="stepUp">
                  <span class="mr-3">Next step</span>
                  <span class="icon icon-chevron-right"></span>
              </button>
            </div>
          </div>
          <div class="mt-3 mb-3 md:mb-0 md:text-right">
           <small>Chrome, Firefox, or Safari browsers are recommended.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sticky from 'vue-sticky-directive'
import Cleave from 'vue-cleave-component';
import { TimelineLite, CSSPlugin, Expo }  from "gsap/all";

// Tree shaking will remove the necessary gsap plugins if it doesn't think they are being used
// eslint-disable-next-line
const plugins = [ CSSPlugin, Expo ];

export default {
  name: 'patients',
  props: {
    mobileFormat: Boolean,
    preferences: Object
  },

  components: {
    Cleave
  },

  directives: {Sticky},

  data () {
    return {
      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'thousand',
        stripLeadingZeroes: true,
        numeralDecimalScale: 0
      }
    }
  },

  mounted() {
    this.animateIn();
  },

  methods: {
    animateIn() {
      const timeline = new TimelineLite();

      if (window.innerWidth < 1024) {
        timeline.fromTo(this.$refs.step__subheadline2, 1,
          {y:-20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut })

        timeline.fromTo(this.$refs.step__headline2, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')

        timeline.fromTo(this.$refs.step__content2, 0.66,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.5')
      } else {
        timeline.fromTo(this.$refs.step__subheadline1, 1,
          {y:-20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut })

        timeline.fromTo(this.$refs.step__headline1, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')

        timeline.fromTo(this.$refs.step__content1, 0.66,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.5')
      }

      timeline.fromTo(this.$refs.step__tipicon, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__tip, 1,
        {y:30, autoAlpha: 0},
        {y:0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__col1, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__col2, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')
    },

    // Emit an event telling the calculator to proceed to the next step
    stepUp() {
      this.$emit('stepUp');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .calculator-content {
    background-image: url(../assets/step1-hero.jpg);
  }

  .shadow-inner {
    box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  }

  .tip-icon {
    width: 32px;
    min-width: 32px;
  }
</style>

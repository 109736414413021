<template>
  <div class="component-brand-table mt-1"> 
    <table class="table-auto w-borderComp">
      <thead>
        <tr v-if="filteredData && filteredData.length > 0">
          <th colspan="5">
            
          </th>
          <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-center">
            <span class="icon icon-arrow-down text-red-500 text-3xl leading-none"></span>
          </th>
          <th>
            
          </th>
        </tr>
        <tr>
          <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Manufacturer</th>
          <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Brand name</th>
          <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Presentation</th>
          <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Size</th>
          <th class="px-3 py-1 align-bottom print:px-1 print:py-1 text-left print:text-xxs print:leading-none">Age indication</th>
          <th class="font-normal px-3 py-1 align-bottom text-center print:text-xxs text-black bg-gray-300">Total orderable units</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <template v-if="filteredData && filteredData.length > 0">
          <brand-table-row 
            v-for="(dosage, dosageIndex) in filteredData" 
            v-bind:dosage="dosage"
            v-bind:key="dosageIndex"/>
            <tr>
              <th colspan="5">
                
              </th>
              <td class="px-3 py-2 align-bottom print:px-1 print:py-1 text-center">
                <span class="icon icon-arrow-up text-red-500 text-3xl leading-none"></span>
              </td>
              <th>
                
              </th>
            </tr>
          </template>
          <template v-if="!filteredData || filteredData.length == 0">
            <tr>
              <td colspan="7" class="border border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1">
                <h3 class="text-sm text-gray-500">You have not set a quantity for any doses.</h3>
              </td>
            </tr>
          </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import BrandTableRow from './BrandTableRow.vue'

export default {
  name: 'brand-table',
  props: {
    dosages: Array,
    totalDoses: Number,
    totalUnits: Number
  },
  components: {
    BrandTableRow
  },

  data() {
    return {

    }
  },

  computed: {
    filteredData() {
      let dosages = this.dosages.filter(function(dosage){
        return (dosage.infant_doses || dosage.youth_doses || dosage.adult_doses || dosage.senior_doses);
      })

      return dosages;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

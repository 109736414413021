<template>
  <div class="fadeIn fixed inset-0 z-50 overflow-auto bg-smoke flex py-8 px-5" ref="modal__bg">
    <div class="absolute z-10 inset-0" @click="close"></div>
    <div class="animated z-20 absolute bottom-0 left-0 mb-3 mx-3 right-0 p-5 pt-10 bg-white shadow-2xl flex-1" style="opacity: 0;transform:translateY(300px);" ref="modal__dialog">

      <span @click="close" class="absolute top-0 left-0 pt-1 px-2">
        <span class="text-2xl">&times;</span>
      </span>

      <p class="leading-normal mb-3 text-center">
        Tap the <strong>action/share</strong> button (<span class="icon icon-share text-xl "></span>) on your device below, then hit <strong>"Save PDF to iBooks"</strong> to save the page.
      </p>
      <div class="flex">
        <button @click="print" class="text-white font-bold bg-red-500 hover:bg-red-600 rounded px-8 py-4 text-center flex-1">
          Got It!
         </button>
      </div>
    </div>
  </div>
</template>

<script>
import { TimelineLite, CSSPlugin, Expo }  from "gsap/all";

// Tree shaking will remove the necessary gsap plugins if it doesn't think they are being used
// eslint-disable-next-line
const plugins = [ CSSPlugin, Expo ];

export default {
  name: 'modal',

  props: {
    open: Boolean
  },

  mounted() {
    this.animateIn();
  },

  methods: {
    animateIn() {
      const timeline = new TimelineLite();

      timeline.fromTo(this.$refs.modal__bg, 0.5, 
        {autoAlpha: 0}, 
        {autoAlpha: 1, ease: Expo.easeInOut }) 

      timeline.fromTo(this.$refs.modal__dialog, 0.5, 
        {y:50, autoAlpha: 0}, 
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.2') 
    },

    print() {
      this.$emit('print');
    },

    close() {
      this.$emit('close');
    }
  }
}
</script>
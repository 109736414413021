<template>
  <div class="bg-white">
    <div class="calculator-content p-logo-x h-40 md:h-64 py-12 relative">
      <div class="hidden lg:block lg:w-1/2 pr-4 relative z-20">
        <h4 ref="step__subheadline1" class="text-red-500 text-lg md:text-xl xl:text-2xl mb-0">Step 3:</h4>
        <h3 ref="step__headline1" class="font-bold leading-vtight text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-2">Customize your summary below by allocating the number of doses within each age group.</h3>
        <span class="text-lg" ref="step__notice1">Below are the flu vaccines that meet your criteria.</span>
      </div>
      <div class="hero-overlay absolute top-0 bottom-0 left-0 w-full z-10"></div>
    </div>
    <div class="bg-gray-300 lg:bg-gray-700 p-logo-x py-8 lg:text-white mb-8">
      <div class="lg:hidden mb-3">
        <h4 ref="step__subheadline2" class="text-red-500 text-lg md:text-xl xl:text-2xl mb-0">Step 3:</h4>
        <h3 ref="step__headline2" class="font-bold leading-vtight text-2xl md:text-3xl mb-2">Customize your summary below by allocating the number of doses within each age group.</h3>
        <span class="text-lg" ref="step__notice2">Below are the flu vaccines that meet your criteria.</span>
      </div>

      <div class="md:flex">
        <div class="flex flex-grow-0 flex-shrink-0 mr-5">
          <div class="mr-2" ref="step__tipicon">
             <span class="icon icon-bulb text-2xl md:text-4xl"></span>
          </div>
          <div ref="step__tip">
            <h3 class="font-bold leading-tight text-2xl md:mt-2 mb-1">Tip:</h3>
          </div>
        </div>

        <div class="w-full md:w-auto md:pr-6" ref="step__col1">
          <p class="mt-3 mb-0 text-sm">
            Diversifying vaccine brands will help ensure availability in the event of an unexpected manufacturer supply disruption.
          </p>
        </div>
      </div>
    </div>

    <div v-sticky on-stick="dosageHeaderStuck" class="sticky-header bg-white" ref="stickyHeader" v-bind:class="(inputFocused ? 'hidden' : '')">
      <div class="flex xl:flex-no-wrap items-center px-3 md-p-logo-x p-overwrite">
        <div class="lg:ml-10 xl:mt-0 mr-3 md:flex-shrink-0 w-full md:w-auto md:flex-1">
          <h1 class="font-light leading-none text-xl sm:text-2xl md:text-3xl print:text-2xl mb-2">2023–2024 Flu vaccine summary</h1>

          <ul class="hidden lg:block text-sm ml-auto mb-0 print:hidden text-gray-500">
            <li class="inline mr-3 md:mr-3 text-xs md:text-sm mr-2"><sup>†</sup> Preservative free</li>
            <li class="mr-2 md:mr-3 inline text-xs md:text-sm"><sup>∆</sup> Packaging not made with natural rubber latex</li>
            <li class="mr-2 md:mr-3 inline text-xs md:text-sm"><sup>∞</sup> Egg free</li>
            <li class="inline text-xs md:text-sm"><sup>Ω</sup> Contains adjuvant called MF59</li>
          </ul>
        </div>

        <div class="flex xl:justify-end xl:ml-auto">
          <button
            class="ga:printsummary text-red-500 font-bold hover:text-red-700 py-2 lg:py-3 md:px-3 lg:mr-5 flex items-center text-center transition-color  print:hidden"
            @click="requestPrint">
              <div>
                <span class="icon icon-print md:mb-1 text-xl block"></span>
                <span>Print</span>
              </div>
          </button>
          <div class="hidden lg:inline-block bg-blue-200 px-3 py-3">
            <h3 class="font-bold mb-1 text-center">Grand totals:</h3>
            <div class="flex-grow text-center">
              <h4 class="leading-none">{{totalDoses.toLocaleString()}} doses needed</h4>
            </div>
            <div class="flex-grow text-center">
              <h4 class="leading-none">({{(totalUnits * 10).toLocaleString()}} doses calculated<sup>*</sup>)</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="print:hidden block lg:hidden px-3 md-p-logo-x p-overwrite">
        <ul class="block md:flex md:flex-row text-sm ml-auto mb-3 print:hidden text-gray-500 leading-none">
          <li class="inline-block mr-2 md:mr-3 lg:flex text-xxs sm:text-xs lg:text-sm mr-2">† Preservative free</li>
          <li class="inline-block mr-2 md:mr-3 inline lg:flex text-xxs sm:text-xs lg:text-sm">∆ Packaging not made with natural rubber latex</li>
          <li class="inline-block mr-2 md:mr-3 inline lg:flex text-xxs sm:text-xs lg:text-sm">∞ Egg free</li>
          <li class="inline-block lg:flex text-xxs sm:text-xs lg:text-sm">Ω Contains adjuvant called MF59</li>
        </ul>
      </div>

      <div class="flex px-2 md-p-logo-x p-overwrite" v-scroll-spy-active v-scroll-spy-link>
        <div class="w-full scroll-nav flex flex-1 items-center items-stretch" v-scroll-spy-active="{class: 'customActive'}" v-scroll-spy-link>
            <button v-bind:class="[((section == 0) ? 'bg-red-500 text-white' : 'bg-gray-300'),'font-bold hover:bg-red-600 hover:text-white transition-all px-3 py-2 text-center flex-1 items-center mr-1 print:hidden outline-none focus:outline-none']" @click="scrollToTable('table__infant')">
              Infant<span class="hidden md:block lg:inline md:text-xs xl:text-sm"> (6 months to 35 months)</span>
            </button>

            <button v-bind:class="[((section == 1) ? 'bg-red-500 text-white' : 'bg-gray-300'),'font-bold hover:bg-red-600 hover:text-white transition-all px-3 py-2 text-center flex-1 items-center mr-1 print:hidden outline-none focus:outline-none']" @click="scrollToTable('table__youth')">
              Youth<span class="hidden md:block lg:inline md:text-xs xl:text-sm"> (36 months to 18 years)</span>
            </button>

            <button v-bind:class="[((section == 2) ? 'bg-red-500 text-white' : 'bg-gray-300'),'font-bold hover:bg-red-600 hover:text-white transition-all px-3 py-2 text-center flex-1 items-center mr-1 print:hidden outline-none focus:outline-none']" @click="scrollToTable('table__adult')">
              Adult<span class="hidden md:block lg:inline md:text-xs xl:text-sm"> (18 years+)</span>
            </button>

            <button v-bind:class="[((section == 3) ? 'bg-red-500 text-white' : 'bg-gray-300'),'font-bold hover:bg-red-600 hover:text-white transition-all px-3 py-2 text-center flex-1 items-center print:hidden outline-none focus:outline-none']" @click="scrollToTable('table__senior')">
              Senior<span class="hidden md:block lg:inline md:text-xs xl:text-sm"> (65 years+)</span>
            </button>
        </div>
      </div>
    </div>

    <div class="hidden">
      <div ref="printHeader">
        <div class="flex mb-6">
          <div class="flex-grow-0 mb-0 mr-6">
            <img src="../assets/logo-main.png" class="cardinal-logo-img ml-1 lg:ml-4">
          </div>

          <div class="flex items-end mr-auto w-auto xl:text-center">
            <h1 class="lg:w-auto text-root sm:text-xl lg:text-3xl font-light header-items logo-subtext leading-tight">
              2023–2024 Flu vaccine calculator
            </h1>
          </div>
        </div>
      </div>

      <div ref="brandContent">
        <p class="font-bold mb-3 text-sm pt-6 border-t border-solid border-gray-700">{{currentDate}}</p>

        <p class="mb-3 text-sm print:text-xxs">Thank you for using the Cardinal Health Flu Vaccine Calculator to estimate your vaccine needs for the upcoming season.</p>

        <p class="mb-3 text-sm print:text-xxs">To place your order, please log in to the Flu Care Order Management Portal by visiting <a href="https://info.cardinalhealth.com/l/34802/2019-12-17/29rsxsl" class="font-bold hover:text-red-500 transition-color">www.cardinalhealth.com/flucare</a>.  If you have any questions, please call 833.FLU.TEAM (833.358.8326) or email <a href="mailto:FluTeam@cardinalhealth.com" class="hover:text-red-500 transition-color underline">FluTeam@cardinalhealth.com</a>.</p>

        <p class="mb-3 text-sm print:text-xxs">Some vaccine products may have limited availability and restricted eligibility. Please log in to Flu Care to verify which vaccines are available to you for purchase. </p>

        <h2 class="font-bold text-red-500 text-xl mb-4">Summary by flu vaccine brand</h2>

        <p class="mb-3 text-sm print:text-xxs">This summary has been formatted so you can easily transfer your orderable units into the Flu Care Order Management Portal. When placing your order in Flu Care, reference the <span class="font-bold">“Total orderable units”</span> column highlighted in the summary below.</p>

      </div>
    </div>

    <div v-if="dosages && dosages.length > 0" class="hidden">
      <div ref="printSummary">
        <brand-table v-bind:dosages="localDosages" v-bind:totalDoses="totalDoses" v-bind:totalUnits="totalUnits"></brand-table>
      </div>
    </div>

    <div class="hidden">
      <div ref="doseContent">
        <ul class="block mt-3 text-sm mb-3 text-gray-500">
          <li class="inline mr-2 md:mr-3 text-tiny print:text-xxs mr-2"><sup>†</sup> Preservative free</li>
          <li class="mr-2 md:mr-3 inline text-tiny print:text-xxs"><sup>∆</sup> Packaging not made with natural rubber latex</li>
          <li class="mr-2 md:mr-3 inline text-tiny print:text-xxs"><sup>∞</sup> Egg free</li>
          <li class="inline text-tiny print:text-xxs"><sup>Ω</sup> Contains adjuvant called MF59</li>
        </ul>
      </div>

      <div ref="ageIndicationContent">
        <h2 class="font-bold text-red-500 text-xl mb-4">Summary by age indication</h2>

        <p class="text-sm print:text-xxs">Below is your flu vaccine summary from Step 3 in the flu vaccine calculator. </p>

      </div>
    </div>

    <div class="md-p-logo-x p-overwrite px-3" v-scroll-spy="{data: 'section', allowNoActive: true, offset: stickyHeaderHeight}">
      <template v-if="dosages && dosages.length > 0">
        <div ref="table__infant" class="mt-12 lg:mx-10">
          <dosage-table
            v-bind:ageMin="6"
            v-bind:ageMax="35"
            v-bind:ageIndication="'infant'"
            v-bind:preferences="selectedPreferences.infant"
            v-bind:title="'Infant flu vaccines (6 months to 35 months)'"
            v-bind:dosages="localDosages"
            v-bind:mobileFormat="currentMobileFormat"
            @update="dosageUpdated"
            @inputFocused="dosageFocused"
            @inputBlurred="dosageBlurred"
            ></dosage-table>
        </div>

        <div ref="table__youth" class="md:mx-10">
          <dosage-table
            v-bind:ageMin="36"
            v-bind:ageMax="215"
            v-bind:ageIndication="'youth'"
            v-bind:preferences="selectedPreferences.youth"
            v-bind:title="'Youth flu vaccines (36 months to 18 years)'"
            v-bind:dosages="localDosages"
            v-bind:mobileFormat="currentMobileFormat"
            @inputFocused="dosageFocused"
            @inputBlurred="dosageBlurred"
            ></dosage-table>
        </div>

        <div ref="table__adult" class="md:mx-10">
          <dosage-table
            v-bind:ageMin="216"
            v-bind:ageMax="779"
            v-bind:ageIndication="'adult'"
            v-bind:preferences="selectedPreferences.adult"
            v-bind:title="'Adult flu vaccines (18 years+): Includes patients and staff'"
            v-bind:dosages="localDosages"
            v-bind:mobileFormat="currentMobileFormat"
            @inputFocused="dosageFocused"
            @inputBlurred="dosageBlurred"
            ></dosage-table>
        </div>

        <div ref="table__senior" class="md:mx-10">
          <dosage-table
            v-bind:ageMin="780"
            v-bind:ageMax="99999"
            v-bind:ageIndication="'senior'"
            v-bind:preferences="selectedPreferences.senior"
            v-bind:title="'Senior flu vaccines (65 years+): Includes patients and staff'"
            v-bind:dosages="localDosages"
            v-bind:mobileFormat="currentMobileFormat"
            @inputFocused="dosageFocused"
            @inputBlurred="dosageBlurred"
          ></dosage-table>
        </div>
      </template>
    </div>

    <div class="sticky-footer bg-white pb-0" v-bind:class="(inputFocused ? 'hidden' : '')" v-sticky="footerStuck" sticky-side="bottom">
      <div class="flex justify-between lg:hidden bg-blue-200 py-2 px-3 text-center">
        <h4 class="font-bold text-xs">Grand Totals:</h4>
        <h4 class="font-light my-0 text-xs"><span class="mr-3">{{totalDoses.toLocaleString()}} doses needed</span><span>({{(totalUnits * 10).toLocaleString()}} doses calculated<sup>*</sup>)</span></h4>
      </div>

      <div class="flex justify-center mt-3 md:mt-5 mb-1 md:mb-3 px-4 md-p-logo-x p-overwrite">
        <div class="hidden md:flex flex-col lg:flex-row flex-1">
          <div class="mr-4">
            <button class="ga:printsummary text-red-500 hover:text-red-600 transition-color" @click="requestPrint">
              <span class="icon icon-print text-5xl"></span>
            </button>
          </div>
          <p class="text-xxs lg:text-xs mb-0 mt-1 lg:mt-0">
            Print your summary to easily populate orderable units in Flu Care. <br class="hidden lg:block"/>
            Print functionality is only optimized for Google Chrome, Firefox, and Safari. <br class="hidden lg:block"/>
            Pop-up blocker must be disabled.
          </p>
        </div>
        <div class="">
          <div class="text-right flex-1 flex items-center justify-center sm:justify-end">
            <div class="flex flex-col justify-start sm:flex-row">
              <button
                class="ga:reset inline-block w-full sm:w-auto text-white font-bold text-red-500 hover:text-red-700 transition-color rounded sm:px-3 sm:py-3 mr-3 items-center text-left sm:text-center"
                @click="stepDown">
                  <span class="sm:flex sm:text-center">
                    <span class="icon icon-chevron-left"></span>
                    <span class="ml-3 leading-tight">Back</span>
                  </span>
              </button>
              <button
                class="ga:reset inline-block w-full sm:w-auto text-white font-bold text-red-500 hover:text-red-700 transition-color rounded sm:px-3 sm:py-3 mr-6 sm:mr-3 items-center text-left sm:text-center"
                @click="reset">
                  <span class="sm:flex xl:inline-block sm:items-center sm:text-center sm:text-left">
                    <span class="icon icon-rotate-right"></span>
                    <span class="ml-3 leading-tight">Start Over</span>
                  </span>
              </button>
            </div>
            <a href="https://info.cardinalhealth.com/l/34802/2019-12-17/29rsxsl"
              class="ga:logintoflucare inline-flex w-3/5 sm:w-auto leading-tight text-white font-bold bg-red-500 hover:bg-red-600 rounded px-3 py-3 items-center text-center print:hidden transition-bg justify-around max-w-50 sm:max-w-full lg:whitespace-no-wrap"
              target="_blank">
              <span class="leading-none">
                Log in to Flu Care to place my order
              </span>
            </a>
          </div>
          <div class="flucare-legalese mt-2 md:mt-1 md:ml-auto mb-2">
            <p class="text-xxs leading-tight">
              *Based on the flu vaccines selected when diversifying your order and the unit/dose packaging of each product type, there may be an excess of doses and units calculated. Please review your orderable units prior to placing your order in Flu Care.
            </p>
          </div>
        </div>
      </div>
    </div>

    <template v-if="modalOpen">
      <modal v-bind:open="modalOpen" @close="modalOpen = false" @print="modalPrint"></modal>
    </template>

  </div>
</template>


<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import Sticky from 'vue-sticky-directive'
import { TimelineLite, CSSPlugin, Expo }  from "gsap/all";


import BrandTable from './BrandTable.vue'
import DosageTable from './DosageTable.vue'
import Modal from './Modal.vue'

import scrollSpy, { Easing } from "vue2-scrollspy"

Vue.use(scrollSpy, {
  easing: Easing.Cubic.In
});


// Tree shaking will remove the necessary gsap plugins if it doesn't think they are being used
// eslint-disable-next-line
const plugins = [ CSSPlugin, Expo ];

export default {
  name: 'flu-summary',

  components: {
    DosageTable,
    BrandTable,
    Modal
  },

  directives: {Sticky},

  props: {
    mobileFormat: Boolean,
    dosages: Array,
    selectedPreferences: Object
  },

  data() {
    return {
      currentMobileFormat: true,
      iOS: false,
      offsets: {top: 0, bottom: 0},
      stickyHeaderHeight: 120,
      section: 0,
      footerStuck: false,
      inputFocused: false,
      localDosages: [],
      modalOpen: false,
      currentDate: null
    }
  },

  mounted() {
    this.stickyHeaderHeight = this.$refs.stickyHeader.offsetHeight + 30;

    this.currentMobileFormat = JSON.parse(JSON.stringify(this.mobileFormat));

    this.animateIn();
    this.initializeForms();
    // this.detectIOS();

    this.currentDate = (new Date()).toLocaleDateString();
  },


  methods: {
    detectIOS() {
      let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      this.iOS = iOS;
    },

    animateIn() {
      const timeline = new TimelineLite();

      if(window.innerWidth < 1024) {
        timeline.fromTo(this.$refs.step__subheadline2, 1,
          {y:-20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut })

        timeline.fromTo(this.$refs.step__headline2, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')

        timeline.fromTo(this.$refs.step__notice2, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')
      } else {
        timeline.fromTo(this.$refs.step__subheadline1, 1,
          {y:-20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut })

        timeline.fromTo(this.$refs.step__headline1, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')

        timeline.fromTo(this.$refs.step__notice1, 1,
          {y:20, autoAlpha: 0},
          {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
          '-=0.95')
      }

      timeline.fromTo(this.$refs.step__tipicon, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__tip, 1,
        {y:30, autoAlpha: 0},
        {y:0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')

      timeline.fromTo(this.$refs.step__col1, 1,
        {y:30, autoAlpha: 0},
        {y: 0, autoAlpha: 1, ease: Expo.easeInOut },
        '-=0.88')
    },

    dosageHeaderStuck(data) {
      if (data.sticked) {
        this.footerStuck = true;
      } else {
        this.footerStuck = false;
      }
    },

    // When a use focuses on an input in mobile, scroll to that element to prevent losing
    // the elment behind keyboard / toolbar during the native focus
    dosageFocused(event) {
      this.inputFocused = true;

      let target = event.target;

      let options = {
        container: "body",
        easing: 'ease-in',
        offset: 0, // Try to buffer this a tad
        force: true,
        cancelable: true,
        x: false,
        y: true
      }

      // Wait a half second to allow the native keyboard to pop up and redraw before scrolling
      window.setTimeout(function() {
        let cancelScroll = VueScrollTo.scrollTo(target, 300, options);
        cancelScroll;
      }, 500);

    },

    dosageBlurred() {
      // eslint-disable-next-line no-console
      console.log('PARENT: a dosages was blurred');
      this.inputFocused = false;
    },


    requestPrint() {
      let vm = this;

      // This removed conditional statement is meant to allow for custom functionality from an iOS device
      // which doesn't natively support save to PDF from the print dialog
      // if (vm.iOS) {
      //   vm.modalOpen = true;
      // } else {
        vm.printResults();
      // }
    },

    modalPrint() {
      this.printResults();
      this.modalOpen = false;
    },

    printResults() {
      let vm = this;
      vm.modalOpen = false;

      // Reset the mobile formatting
      vm.currentMobileFormat = false;

      // Let the view update before duplicating elements
      Vue.nextTick(function () {

        //Get the HTML of div
        let title = document.title;
        let divElements = vm.$refs.printHeader.innerHTML;
        divElements += '<div class="p-logo-x">';
        divElements += vm.$refs.brandContent.innerHTML;
        divElements += vm.$refs.printSummary.innerHTML;
        divElements += vm.$refs.doseContent.innerHTML;
        divElements += vm.$refs.ageIndicationContent.innerHTML;
        divElements += vm.$refs.table__infant.innerHTML;
        divElements += vm.$refs.table__youth.innerHTML;
        divElements += vm.$refs.table__adult.innerHTML;
        divElements += vm.$refs.table__senior.innerHTML;
        divElements += vm.$refs.doseContent.innerHTML;
        divElements += '</div>';

        let printWindow = window.open("", "_blank", "");
        //open the window
        printWindow.document.open();
        //write the html to the new window, link to css files
        printWindow.document.write('<!DOCTYPE html><html><head><title>' + title + '</title><link rel="stylesheet" type="text/css" href="/css/app.css"><style type="text/css">.dose-field{display: none !important;}.dose-number{display:block !important}</style></head><body style="background-color:#fff;">');
        printWindow.document.write(divElements);
        printWindow.document.write('</body></html>');
        // printWindow.document.close();
        printWindow.focus();
        //The Timeout is ONLY to make Safari work, but it still works with FF, IE & Chrome.
        setTimeout(function() {

            // IE11 refuses to open the print dialog, so force it to reload to open
            if (!!window.MSInputMethodContext && !!document.documentMode) {
              printWindow.location.reload();
            }

            printWindow.print();

            // Set the format back
            vm.currentMobileFormat = JSON.parse(JSON.stringify(vm.mobileFormat));
            // printWindow.close(); // Normally you would close the generated window when the print dialog is closed; but we want it to persist so users can reference the summary
        }, 500);
      });
    },

    scrollTop() {
      let vm = this;
      let options = {
        container: 'body',
        easing: false,
        offset: 0,
        force: true,
        duration: 5,
        cancelable: false,
        x: false,
        y: true
      }

      let cancelScroll = VueScrollTo.scrollTo(vm.$refs.wrapper, 300, options);
      cancelScroll;
    },

    scrollToTable(location) {
      let vm = this;
      let options = {
        container: "body",
        easing: 'ease-in',
        offset: -vm.stickyHeaderHeight + 15, // Try to buffer this a tad
        force: true,
        cancelable: true,
        x: false,
        y: true
      }

      let cancelScroll = VueScrollTo.scrollTo(vm.$refs[location], 300, options);
      cancelScroll;
    },

    // Loop over the original dosage options given to the component and add a reactive property for alotted doses
    initializeForms() {
      let vm = this;
      let dt = (new Date).valueOf();

      vm.localDosages = JSON.parse(JSON.stringify(vm.dosages));

      vm.localDosages.forEach(function(dosage,index){
        vm.$set(dosage, "id", (dt+'_'+index));
        vm.$set(dosage, "infant_doses", null);
        vm.$set(dosage, "youth_doses", null);
        vm.$set(dosage, "adult_doses", null);
        vm.$set(dosage, "senior_doses", null);
      });
    },

    stepDown() {
      this.$emit('stepDown');
    },

    reset() {
      this.$emit('reset');
    },

    dosageUpdated(qty, dosage, ageIndication) {
      dosage[`${ageIndication}_doses`] = qty;
    }

  },

  computed: {
    totalDoses() {
      let vm = this;

      let total = 0;

      vm.localDosages.forEach(function(dosage){
        total += dosage.infant_doses;
        total += dosage.youth_doses;
        total += dosage.adult_doses;
        total += dosage.senior_doses;
      });

      return total;
    },

    totalUnits() {
      let vm = this;

      let total = 0;

      vm.localDosages.forEach(function(dosage){
        total += (dosage.infant_doses ? Math.ceil(dosage.infant_doses/10) : 0);
        total += (dosage.youth_doses ? Math.ceil(dosage.youth_doses/10) : 0);
        total += (dosage.adult_doses ? Math.ceil(dosage.adult_doses/10) : 0);
        total += (dosage.senior_doses ? Math.ceil(dosage.senior_doses/10) : 0);
      });

      return total;
    },
  }
}
</script>

<style scoped lang="scss">
  .calculator-content {
    background-image: url(../assets/step3-hero.jpg);
  }
  .flucare-legalese {
    @media screen and (min-width: 767px) {
      width: 296px;
    }
  }
</style>

<template>
  <div id="app" class="container font-body text-gray-800">
    <calculator/>
  </div>
</template>

<script>
// Components
import Calculator from './components/Calculator.vue';

export default {
  name: 'app',
  components: {
    Calculator
  }
}
</script>

<style lang="scss">

</style>

<template>
	<tr v-bind:class="[(!mobileFormat ? 'bg-gray-100' : 'bg-gray-100 border-b-8 border-solid border-white' ), (disabled ? 'hidden' : ''), 'print:bg-transparent']">

    <template v-if="mobileFormat">
      <td colspan="6" class="px-3 py-1">
        <div class="dosage-row">
          <div class="flex items-center flex-col text-left w-full max-w-40 pr-2">
            <strong class="w-full block leading-none">QTY</strong>
            <span class="w-full text-xs text-gray-500 italic leading-none">10 doses per unit</span>
          </div>
          <div class="flex flex-1 items-center pr-2">
            <cleave 
              :options="cleaveOptions" 
              v-model="localQuantity" 
              class="dose-field block mx-auto w-60 text-gray-700 text-center border-solid border-2 border-gray-500 mr-2 rounded print:p-0 print:border-0 print:text-center print:bg-transparent" 
              v-bind:class="[(localQuantity > 0 ? 'bg-blue-200' : '' )]"
              :disabled="disabled" 
              placeholder="0"
              maxlength="9"
              pattern="[0-9]*" 
              inputmode="numeric"
              @focus.native="onFocusInput"
              @blur="onBlurInput"></cleave>
              <span class="text-sm">doses</span>
          </div>
          <div class="flex-1 text-right text-sm pr-2">
            {{unitsNeeded.toLocaleString()}} units
          </div>
        </div>

        <div class="dosage-row">
          <div class="flex w-full max-w-40">
            <strong>Manufacturer</strong>
          </div>
          <div class="flex-1">
            {{dosage.Manufacturer}}
          </div>
        </div>

        <div class="dosage-row">
          <div class="flex w-full max-w-40">
            <strong>Brand</strong>
          </div>
          <div class="flex-1">
            <span v-html="this.$options.filters.setSuperSymbols(dosage.Brand)"></span>
          </div>
        </div>

        <div class="dosage-row">
          <div class="flex w-full max-w-40">
            <strong>Presentation</strong>
          </div>
          <div class="flex-1">
            <span v-html="this.$options.filters.setSuperSymbols(dosage.Presentation)"></span>
          </div>
        </div>

        <div class="dosage-row">
          <div class="flex w-full max-w-40">
            <strong class="mr-2">Size</strong> {{dosage.Size}}
          </div>
          <div class="flex-1">
            <strong class="mr-2">Age</strong> {{getAge(dosage)}}
          </div>
        </div>
      </td>
    </template>

    <template v-if="!mobileFormat">
      <td class="border border-white print:border-gray-700 border-solid px-2 py-1">
        <div class="flex flex-row items-center">
          <div class="w-1/3">
            <span class="dose-number block text-center hidden">{{getQty}}</span>
            <cleave 
              :options="cleaveOptions" 
              v-model="localQuantity" 
              class="dose-field block mx-auto w-full max-w-3 text-gray-700 print:text-black text-center border-solid border-2 border-gray-500 rounded print:p-0 print:border-0 print:bg-transparent print:text-center print:placeholder-gray-800::placeholder" 
              v-bind:class="[(localQuantity > 0 ? 'bg-blue-200' : '' )]"
              :disabled="disabled" 
              placeholder="0"
              maxlength="9"
              pattern="[0-9]*" 
              inputmode="numeric"></cleave>
          </div>
          <div class="w-2/3 text-center">
            <span v-bind:class="[(disabled ? 'text-gray-700' : ' text-gray-900')]">
              {{unitsNeeded.toLocaleString()}}
            </span>
          </div>
        </div>
      </td>
      <td class="border border-white print:border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1 text-root sm:text-xs md:text-root leading-tight">
        {{dosage.Manufacturer}}
      </td>
      <td class="border border-white print:border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1 text-root sm:text-xs md:text-root leading-tight">
        <span v-html="this.$options.filters.setSuperSymbols(dosage.Brand)"></span>
      </td>
      <td class="border border-white print:border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1 text-root sm:text-xs md:text-root leading-tight">
        <span v-html="this.$options.filters.setSuperSymbols(dosage.Presentation)"></span>
      </td>
      <td class="border border-white print:border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1 text-root sm:text-xs md:text-root leading-tight">
        {{dosage.Size}}
      </td>
      <td class="border border-white print:border-gray-700 border-solid px-3 py-2 print:px-1 print:py-1 text-root sm:text-xs md:text-root leading-tight">
        {{getAge(dosage)}}
      </td>
    </template>
  </tr>
</template>


<script>
  import Cleave from 'vue-cleave-component';

  export default {
    name: 'dosage-table-row',
    props: {
      dosage: Object,
      quantity: Number,
      dosageIndex: Number,
      disabled: Boolean,
      ageIndication: String,
      mobileFormat: Boolean
    },    

    components: {
      Cleave
    },

    data () {
      return {
        localQuantity: null,
        cleaveOptions: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'thousand',
          stripLeadingZeroes: true,
          numeralDecimalScale: 0
        }      
      }
    },

    methods: {
      setMin() {
        if (parseFloat(this.localQuantity) < 0) {
          this.localQuantity = 0;
        }
      },

      /* Params: value:integer
      ** Given an integer of months, will return a human friendly string for
      ** how many months or years that value should be displayed as
      */
      getYears(value) {
        let calculatedValue = value;
        if (value > 36) {
          calculatedValue = (value/12) + ' years';
        } else {
          calculatedValue += ' months';
        }

        return calculatedValue;
      },

      /* Params: dosage:object
      ** Builds a human friendly string for what age/range the dosage should be used for
      */
      getAge(dosage) {
        let vm = this;
        let age = "";

        age += vm.getYears(dosage["Age Indication Min"])

        if (dosage["Age Indication Max"]) {
          age += ' - '
          age += vm.getYears(dosage["Age Indication Max"]);
        } else {
          age += '+';
        }

        return age;
      },

      onFocusInput() {
        this.$emit('inputFocused', event);
      },

      onBlurInput() {
        this.$emit('inputBlurred');
      },

      // quantityChanged() {
      //   let vm = this;
      //   this.emit('updateQty', vm.quantity, vm.dosage, vm.ageIndication);
      // }
    },

    computed: {
      // Calculates the total number of units based on the requested doses the user inputs
      unitsNeeded() {
        let vm = this;
        let total = 0;

        if (vm.localQuantity && vm.localQuantity > 0 && vm.dosage.DosesPerUnit) {
          let dividedUnits = vm.localQuantity / vm.dosage.DosesPerUnit;

          total = Math.ceil(dividedUnits);
        }

        return total;
      },

      getQty() {
        let vm = this;
        let total = 0;

        if (vm.localQuantity || vm.localQuantity == 0) {
          total = parseFloat(vm.localQuantity).toLocaleString();
        }

        return total;
      }
    },

    watch: {
      localQuantity (qty) {
        let vm = this;
        if (qty) {
          let qtyNum = parseFloat(qty);
          this.$emit('update', qtyNum, vm.dosage, vm.ageIndication)
        } else {
          this.$emit('update', null, vm.dosage, vm.ageIndication)
        }
      }
    },

    filters: {
      setSuperSymbols: function (value) {
        let superSymbols = ['†', '∆', '∞', 'Ω'];
        let newContent = '';

        if (!value) return ''

        // Loop over the characters in the string and if it matches are subset of characters, super it
        for (var i = 0; i < value.length; i++) {
          let curr = value.charAt(i);
          if (superSymbols.indexOf(curr) > -1) {
            newContent += '<sup class="text-symbol">'+curr+'</sup>';
          } else {
            newContent += curr;
          }
        }

        return newContent;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .dose-field {
    padding: 0;
    @media screen {
      padding: 3px 3px 1px;
    }
  }
</style>